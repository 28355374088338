<template>
    <v-menu offset-y>
        <template #activator="{ attrs, on }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
                color="indigo"
                :disabled="responses.length == 0"
            >
                <v-icon left>mdi-download</v-icon>
                Download
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item @click="downloadJSON()">
                <v-list-item-title>All data in JSON</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadJSON(true)">
                <v-list-item-title>Selected columns in JSON</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadCSV()">
                <v-list-item-title>Selected columns in CSV</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    props: {
        headers: { type: Array, default: () => ([]) },
        responses: { type: Array, default: () => ([]) },
    },
    methods: {
        accessObjectByDottedKey (obj, key) {
            var keys = key.split('.');
            var value = obj;
            for (var layer = 0, recursive = keys.length; layer <= recursive - 1; layer++) {
                value = value[keys[layer]];
            }
            return value;
        },
        filterColumns() {
            return this.responses.map(r => {
                let data = {};
                this.headers.slice(0,-1).forEach(h => {
                    data[h.value] = this.accessObjectByDottedKey(r, h.value);
                });
                return data;
            });
        },
        downloadJSON(onlySelected = false) {
            const responses = onlySelected ? this.filterColumns() : this.responses;
            const blob = new Blob(
                [JSON.stringify(responses, null, '  ')],
                { type: 'application/json' }
            );
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'responses.json';
            link.click();
        },
        downloadCSV(onlySelected = true) {
            const responses = onlySelected ? this.filterColumns() : this.responses;
            let responsesCSV = Object.keys(responses[0]).join(',');
            responsesCSV += '\n'
                + responses.map((r) => (
                    Object.values(r).map(v => `"${v}"`).join(',')
                )).join('\n');
            const blob = new Blob(
                [responsesCSV],
                { type: 'text/csv' }
            );
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'responses.csv';
            link.click();
        }
    }
}
</script>
