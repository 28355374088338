<template>
    <div>
        <v-card-title>
            <v-select
                hide-details
                width="80%"
                :items="templateNames"
                v-model="templateName"
                label="Template name"
                :disabled="templateNames.length==0"
            />
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchStr"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details>
            </v-text-field>
        </v-card-title>

        <v-data-table
            dense
            :group-by="groupedHeaderValue ? [groupedHeaderValue] : []"
            :headers="headers"
            :items="responses"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [10,30,50,100,300] }"
            :search="searchStr">
            <template #group.header="{ group, groupBy, isOpen, headers, toggle }">
                <td :colspan="headers.length" class="text-start pl-3" style="height:24px">
                    <div class="d-flex">
                        <div class="mr-6">
                            <v-icon
                                small
                                class="mr-2"
                                v-html="isOpen ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'"
                                @click="toggle()"
                            ></v-icon>
                            {{ headersMap[groupBy[0]] }}:
                            <span
                                v-if="groupBy[0]==='_created_at'"
                                v-html="standardDateTimeFormat(group)"
                                class="font-weight-bold"
                            ></span>
                            <span
                                v-else
                                v-html="group"
                                class="font-weight-bold"
                            ></span>
                        </div>
                        <div>
                        </div>    
                    </div>
                </td>
            </template>
            <template #top>
                <v-card-text class="py-0" v-if="allAnswerKeys.length>0">
                    <div class="text-right">
                        <v-menu offset-y :close-on-content-click="false" max-height="500">
                            <template #activator="{ on, attrs }">
                                <v-btn small outlined color="grey darken-2" v-on="on" v-bind="attrs"><v-icon left>mdi-cog-outline</v-icon>Manage Columns</v-btn>
                            </template>
                            <v-card width="400" class="px-2">
                                <v-card-text>
                                    <menu-title>Default Columns Visibility</menu-title>
                                    <v-list>
                                        <v-list-item
                                            class="px-0"
                                            v-for="header in defaultHeaders"
                                            :key="`default-header-${header.value}`"
                                        >
                                            <v-list-item-content>
                                                {{ header.text }}
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-switch inset dense v-model="header.shown" @click="saveShownColumns" />
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>

                                    <menu-title>Answer Columns Visibility</menu-title>
                                    <v-combobox
                                        multiple
                                        :items="allAnswerKeys"
                                        v-model="answerKeys"
                                        label="Select or type an answer key"
                                        class="mt-2"
                                        deletable-chips
                                        small-chips
                                    >
                                    </v-combobox>

                                    <menu-title>Grouped Column</menu-title>
                                    <v-select
                                        v-model="groupedHeaderValue"
                                        :items="[{ text: '---', value: null }, ...headers]"
                                    >
                                    </v-select>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </div>
                </v-card-text>

            </template>

            <template #item.work_session_id="{ item, header }">
                <div :class="header.truncate ? 'text-truncate' : ''" @click="header.truncate = !header.truncate">{{ item.work_session_id }}</div>
            </template>
            <template #item.worker_id="{ item, header }">
                <div :class="header.truncate ? 'text-truncate' : ''" @click="header.truncate = !header.truncate">{{ item.worker_id }}</div>
            </template>
            <template #item.nanotask_id="{ item, header }">
                <div :class="header.truncate ? 'text-truncate' : ''" @click="header.truncate = !header.truncate">{{ item.nanotask_id }}</div>
            </template>

            <template v-slot:item._created_at="{ item }">
                {{ standardDateTimeFormat(item._created_at) }}
            </template>
            <template v-for="name in slotNamesForAnswerKeys" v-slot:[name]="{ value }">
                <template v-if="checkIfAnswerValueNeedsDialog(value)">
                    <v-btn small text :key="`slot-${name}`" @click="showAnswerValueDialog(value)">{ ... }</v-btn>
                </template>
                <template v-else>
                    {{ value }}
                </template>
            </template>
            <!--<template v-slot:item.answers="{ item }">
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="(value, key) in item.answers" :key="key">
                                <td style="width:100px"><b>{{ key }}</b></td>
                                <td style="word-break:break-all">{{ value }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>-->
        </v-data-table>

        <answer-value-dialog ref="dialogAnswerValue" :value="answerValueInDialog">
        </answer-value-dialog>
    </div>
</template>

<script>
import { standardDateTimeFormat, isObject } from '@/lib/utils'
import ManageColumnsMenuTitle from './ResponseTable/ManageColumnsMenuTitle.vue'
import AnswerValueDialog from './ResponseTable/AnswerValueDialog.vue'

export default {
    components: {
        'menu-title': ManageColumnsMenuTitle,
        'answer-value-dialog': AnswerValueDialog
    },
    data: () => ({
        standardDateTimeFormat,
        searchStr: "",
        answerKeys: [],
        defaultHeaders: [
            { text: "Platform", value: "platform", shown: true, truncate: true },
            { text: "Session ID", value: "work_session_id", shown: true, truncate: true },
            { text: "Worker ID", value: "worker_id", shown: true, truncate: true },
            { text: "Platform Worker ID", value: "platform_worker_id", shown: true, truncate: true },
            { text: "Nanotask ID", value: "nanotask_id", shown: true, truncate: true },
            { text: "Submitted time", value: "_created_at", shown: true, truncate: true },
        ],
        templateName: '',
        responses: [],
        groupedHeaderValue: null,
        answerValueInDialog: '',
    }),
    props: ['client', 'projectName', 'templateNames'],
    computed: {
        headers() {
            return [
                ...this.defaultHeaders.filter(h => h.shown),
                ...this.answerKeys.map((k) => ({ text: k, value: `answers.${k}` })),
                //{ text: '', value: 'answerKeysMenu' }
            ];
        },
        allAnswerKeys() {
            return [...new Set(this.responses.flatMap((r) => (r.answers ? Object.keys(r.answers) : []))).values()];
        },
        headersMap() {
            return Object.fromEntries(this.headers.map(h => [h.value, h.text]));
        },
        slotNamesForAnswerKeys() {
            return this.answerKeys.map(key => 'item.answers.'+key);
        },
    },
    methods: {
        checkIfAnswerValueNeedsDialog(val) {
            return isObject(val) || (Array.isArray(val) && JSON.stringify(val).length > 100);
        },
        saveShownColumns() {
            let shownColumns = this.loadShownColumns() || {};
            if(!shownColumns[this.projectName]) shownColumns[this.projectName] = {};
            shownColumns[this.projectName][this.templateName] = {
                    default: Object.fromEntries(this.defaultHeaders.map(header => ([header.value, header.shown]))),
                    answerKeys: this.answerKeys
                };
            window.localStorage.setItem('tuttiResponseColumns', JSON.stringify(shownColumns));
        },
        loadShownColumns() {
            const ret = JSON.parse(window.localStorage.getItem('tuttiResponseColumns'));
            return ret;
        },
        async listResponsesForTemplate(templateName) {
            this.templateName = templateName;
            console.log(this.client);
            this.responses = await this.client.resource.listResponsesForTemplate({
                project_name: this.projectName,
                template_name: templateName
            });
        },
        showAnswerValueDialog(val) {
            this.answerValueInDialog = val;
            this.$refs.dialogAnswerValue.show();
        }
    },
    watch: {
        templateName(name) {
            this.listResponsesForTemplate(name);
        },
        headers() {
            this.$emit('header-update', this.headers);
        },
        responses() {
            const shownColumns = this.loadShownColumns();
            if(shownColumns && shownColumns[this.projectName] && shownColumns[this.projectName][this.templateName]) {
                this.defaultHeaders.forEach(header => {
                    header.shown = shownColumns[this.projectName][this.templateName].default[header.value];
                });
                this.answerKeys = shownColumns[this.projectName][this.templateName].answerKeys;
            } else {
                this.defaultHeaders.forEach(header => { header.shown = true; });
                this.answerKeys = this.allAnswerKeys;
            }
            this.$emit('response-update', this.responses);
        },
        answerKeys() {
            this.saveShownColumns();
        },
    }
}
</script>
<style>
.text-truncate {
    max-width: 100px;
}
</style>
